import routerOptions0 from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/vercel/path0/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  scrollBehaviorType: "smooth",
  hashMode: false
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}